export default function mounted() {
	    
    /** Get Account Heads **/
    this.getJournalTypes('all');

    // // /** Get Account Heads **/
    this.getLedgerHeads();
    this.form.get();
    this.searchJounal();

    // // /** Get Account Heads **/
    // this.getAccountHeads();
    // this.form.get();

}
