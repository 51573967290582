export default {
    // 'voucher_type_head': function (v) {
    //     this.getJournalTypes(v);
    //     this.form.items.data = [];
    //     // this.$rest.get('/api/journal-type?prType='+ v).then(({data}) => {
    //     //     this.journalTypes = data.data.map(item => ({value: item.id, text: item.name}));
    //     // })
    // },
    'journal_type_id':function (v) {
        this.form.items.data = [];
    },
    'form.toDate':function () {
      this.form.items.data = [];
    },
    'form.frmDate':function () {
      this.form.items.data = [];
    },
    'voucher_type_head':function () {
        this.getJournalTypes(v);
        this.form.items.data = [];
        this.journal_type_id = 'all';
    },
    'pagination' : function () {
        this.searchJounal();
    }

}