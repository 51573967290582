<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title" style="margin-bottom: 20px">
            <!-- <v-icon>layers</v-icon> -->
            Journal Entry List
            <v-spacer></v-spacer>
            <add-button
              permission="section-create"
              @action="$router.push({ name: 'journal-entry' })"
              >New Journal
            </add-button>
          </v-card-title>

          <v-card flat>
            <v-card outlined>
              <v-card-title class="title" style="margin-top: 14px">
                <v-flex xs6 sm2>
                  <v-calendar-field
                    outlined
                    dense
                    id-val="today-jl-1"
                    label="From Date"
                    v-model="form.frmDate"
                    prepend-icon="event"
                  />
                </v-flex>
                <v-flex xs4 sm2>
                  <v-calendar-field
                    outlined
                    dense
                    id-val="today-jl-2"
                    label="To date"
                    v-model="form.toDate"
                    prepend-icon="event"
                  />
                </v-flex>
                <v-flex xs4 sm2>
                  <v-autocomplete
                    outlined
                    dense
                    v-model="voucher_type_head"
                    :items="voucherTypeHeads"
                    label="Select Voucher Type"
                    persistent-hint
                  />
                </v-flex>
                <v-flex xs4 sm2>
                  <v-autocomplete
                    outlined
                    dense
                    v-model="journal_type_id"
                    :items="journalTypes"
                    label="Select Journal Type"
                    persistent-hint
                  />
                </v-flex>
                <v-flex xs4 sm2>
                  <v-text-field
                    outlined
                    dense
                    label="Voucher No"
                    v-model="voucher_no"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4 sm2>
                  <add-button
                    style="margin-top: -30px"
                    v-if="form.frmDate"
                    icon="search"
                    :permission="'section-read'"
                    @action="searchJounal"
                    >Search
                  </add-button>
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>

          <!-- Add Ledger Head -->
          <v-dialog v-model="ledgerForm.dialog" max-width="500px" persistent>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="title">Ledger Head Information</span>
              </v-card-title>

              <v-card-text class="has-spinner">
                <!--<spinner light="light" :loading="ledgerForm.loading"/>-->
                <v-container fluid grid-list-sm pa-0>
                  <v-form
                    ref="form"
                    @submit.prevent="store"
                    @keydown.native="
                      ledgerForm.errors.clear($event.target.name)
                    "
                    @keyup.enter="store"
                    lazy-validation
                  >
                    <v-layout wrap>
                      <v-flex xs12 sm6>
                        <v-text-field
                          outlined
                          dense
                          v-model="ledgerForm.name"
                          name="name"
                          label="Name"
                          :error-messages="ledgerForm.errors.get('name')"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 d-flex>
                        <v-select
                          outlined
                          dense
                          v-model="ledgerForm.account_head_id"
                          name="account_head_id"
                          :items="accountHeads"
                          label="Select Account Head"
                          :error-messages="
                            ledgerForm.errors.get('account_head_id')
                          "
                          :height="25"
                        />
                      </v-flex>
                      <v-flex xs12 sm12>
                        <v-textarea
                          outlined
                          dense
                          v-model="ledgerForm.description"
                          name="description"
                          label="Description"
                          :error-messages="ledgerForm.errors.get('description')"
                          autocomplete="off"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="ledgerForm.reset()" outlined
                  >Cancel</v-btn
                >
                <v-btn
                  color="info"
                  :disabled="ledgerForm.errors.any() || ledgerForm.loading"
                  @click="storeLedger"
                  outlined
                  >Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
              <v-card-title class="title pa-3 red white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Please, wait!
              </v-card-title>
              <v-card-text class="ma-0"
                >Are you sure you want to Cancel this item?
                <br />
                <v-textarea
                  v-model="reason"
                  outlined
                  name="input-7-4"
                  label="Reason"
                ></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="(deleteDialog = false), (reason = '')"
                  >No
                </v-btn>
                <v-btn
                  color="error"
                  outlined
                  small
                  @click="deleteItem(), (deleteData = true)"
                  :disabled="!reason"
                  >Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr :style="{ color: item.deleted_at ? '#c5c5c5' : '' }">
                <td width="6%" :class="{ 'red-border': item.deleted_at }">
                  <small>
                    {{
                      item.journal_type ? item.journal_type.name : '-'
                    }}</small
                  >
                </td>
                <td width="8%">
                  <small>{{ item.voucher_no }}</small>
                  <div>
                    <small>
                      <i style="font-size: 10px"
                        >({{
                          item.journal_type
                            ? item.journal_type.pr_type.capitalizeFirstLetter()
                            : '-'
                        }})</i
                      ></small
                    >
                  </div>
                </td>
                <!--<td width="5%">{{props.item.journal_type ? props.item.journal_type.pr_type.capitalizeFirstLetter():'-'}}</td>-->

                <td class="text-xs-left" width="12%">
                  <small> {{ item.entry_date }}</small>
                </td>
                <td class="text-xs-left" width="25%">
                  <tr v-for="(particular, i) in item.ledger_accounts" :key="i">
                    <td
                      v-if="particular.dr > 0"
                      style="position: absolute; margin-left: -25px"
                    >
                      <small> {{ particular.ledger_head }} (Dr.)</small>
                    </td>

                    <td
                      v-else-if="particular.cr > 0"
                      style="position: absolute; margin-left: 35px"
                    >
                      To,
                      <small> {{ particular.ledger_head }}</small>
                    </td>

                    <br />
                  </tr>
                  <!--<span ></span>-->
                  <!--<span v-else style="margin-left: 65px; position: absolute">{{particular.ledger_head}}</span>-->
                  <!--<br/>-->
                  <small style="text-align: left">
                    <span
                      ><i> (Narration: {{ item.narration }})</i></span
                    ></small
                  >
                </td>

                <!--<td class="text-xs-center">{{props.item.lf_id}}</td>-->
                <td class="text-xs-left" width="10%">
                  <tr v-for="(particular, i) in item.ledger_accounts" :key="i">
                    <td
                      v-if="parseInt(particular.dr) > 0"
                      style="position: absolute; margin-top: -12px"
                    >
                      <small> {{ particular.dr.numberFormat() }}</small>
                    </td>
                    <br />
                  </tr>
                </td>

                <td class="text-xs-left" width="10%">
                  <tr v-for="(particular, i) in item.ledger_accounts" :key="i">
                    <td
                      v-if="parseInt(particular.cr) > 0"
                    >
                      <small> {{ particular.cr.numberFormat() }}</small>
                      <br>
                    </td>
                    <!-- <br /> -->
                  </tr>
                </td>

                <!--<td class="text-xs-center">{{props.item.accountant.name}}</td>-->
                <td class="justify-right text-xs-right" width="13%">
                  <div
                    style="display: inline-block"
                    v-if="
                      item.journal_type.pr_type !== 'sales' &&
                      item.journal_type.pr_type !== 'purchase'
                    "
                  >
                    <v-btn
                      @click="printVoucher(item.id)"
                      small
                      icon
                      color="primary"
                      class="pa-0 ma-1"
                    >
                      <v-icon dark>print</v-icon>
                    </v-btn>
                    <!-- Edit and Delete is disabled temporarily (Do not Delete) -->
                    <edit-button
                      v-if="item.is_sod === 0 && item.deleted_at === null"
                      permission="fee-heads-update"
                      @agree="form.edit(item), editItem(item)"
                    />

                    <v-btn
                      v-if="item.is_sod === 0 && item.deleted_at === null"
                      small
                      icon
                      color="error"
                      class="pa-0 ma-1"
                      @click="(deleteDialog = true), (deleteDataId = item.id)"
                    >
                      <v-icon small>clear</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <!--<notification :notification="form.notification" />-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import data from './modules/data';
import computed from './modules/computed';
import mounted from './modules/mounted';
import created from './modules/created';
import methods from './modules/methods';
import watch from './modules/watch';
import Mixins from '../../../../library/Mixins';
export default {
  mixins: [Mixins],
  name: 'JournalList',
  // components: {Spinner,Notification},
  data: () => data,
  mounted: mounted,
  computed: computed,
  methods: methods,
  created: created,
  watch: watch,
};
</script>
<style lang="scss" scoped>

.red-border {
  border-left: 4px solid red;
  transition: border 0.3s;
}

.red-border:hover {
  border-color: darkred;
}
.v-application .text-right {
  text-align: left !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: blue;
}
.v-data-table-header {
  background: blue !important;
}
</style>
