export default {
    editItem(data) {
        this.$router.push({
            path: '/journal-entry/' + data.id,
            query: {
                id: data.id,
                date: data.entry_date,
                data: data
            }
        });
    },

    printVoucher(id) {
        window.open(`${this.downloadUrl}?journal_v_=${id}&withDeleted=true`)
    },

    store() {
        this.form.store();
    },

    storeLedger() {
        this.ledgerForm.store().then(({ data }) => {
            this.getLedgerHeads(data.data.id);
        })
    },

    getJournalTypes(v) {
        this.$rest.get('/api/journal-type?prType=' + v).then(({ data }) => {
            this.journalTypes = data.data.map(item => ({ value: item.id, text: item.name }));
            this.journalTypes.push({ value: 'all', text: 'All' })
        })
    },

    getAccountHeads() {
        this.$rest.get('/api/account-head').then(({ data }) => {
            this.accountHeads = data.data.map(item => ({ value: item.id, text: item.name }));
        })
    },

    getLedgerHeads(id) {
        this.$rest.get('/api/ledger-head').then(({ data }) => {
            this.ledgerHeads = data.data.map(item => ({ value: item.id, text: item.name }));
            if (![undefined, null].includes(id)) {
                let len = this.form.particulars.length;
                if (len > 0) this.form.particulars[len - 1].ledger_head_id = id;
                else this.form.particulars[0].ledger_head_id = id;
            }
        })
    },
    addRow: function () {
        if ([undefined, null].includes(this.form.particulars)) {
            this.form.particulars = [{ ledger_head_id: null, drcr: 'dr', amount: 0 }];
        } else {
            this.form.particulars.push({ ledger_head_id: null, drcr: 'cr', amount: 0 });
        }
    },
    removeElement: function (row) {
        var index = this.form.particulars.indexOf(row);
        this.form.particulars.splice(index, 1);
    },
    searchJounal() {
        this.form.frmDate = this.dateFormat(this.form.frmDate);
        this.form.toDate = this.dateFormat(this.form.toDate);
        this.$rest.get('/api/journal-entry?frmDate=' + this.form.frmDate + '&toDate=' + this.form.toDate + '&journalTypeId=' + this.journal_type_id + '&voucherNo=' + this.voucher_no + '&voucherType=' + this.voucher_type_head + '&page='+ this.pagination.page +'&rowsPerPage=' + this.pagination.itemsPerPage +'&withDeleted='+'true' ).then(({ data }) => {
            this.downloadUrl = data.download_url;
            this.form.items = data;
        });
    },
    dateFormat(data) {
        if (data.length === 8) return data = data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2);
        else return data;
    },
    deleteItem() {
        if (this.deleteDataId) {
            this.$rest.delete('/api/journal-entry-cancel/' + this.deleteDataId + '?reason=' + this.reason).then(res => {
                this.$events.fire('notification', { message: res.data.message, status: 'success' });
                this.deleteDialog = false;
                this.reason = '';
                this.deleteDataId = '';
                this.form.get();
            }).catch(e => {
                this.$events.fire('notification', { message: e.message, status: 'error' });
                this.deleteDataId = '';
            });
        }
    }
}
