import Form from '@/library/Form';
const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  form: new Form(
    {
      narration: '',
      journal_type_id: '',
      frmDate: nd.format('YYYY-MM-DD'),
      toDate: nd.format('YYYY-MM-DD'),
      particulars: [{ ledger_head_id: '', drcr: 'dr', amount: 0 }],
    },
    '/api/journal-entry'
  ),
  downloadUrl: '',
  ledgerForm: new Form(
    {
      name: '',
      description: '',
      account_head_id: '',
    },
    '/api/ledger-head'
  ),

  headers: [
    {
      text: 'J. Type',
      value: 'journal_type',
      align: 'left',
      sortable: false,
      width: 20,
    },
    {
      text: ' V. No./Type ',
      value: 'serial_no',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Date',
      value: 'nepali_date',
      align: 'left',
      sortable: false,
      width: 80,
    },
    { text: 'Particular', value: 'particular', align: 'left', sortable: false },
    { text: 'Dr.', value: 'dr', align: 'left', sortable: false },
    { text: 'Cr.', value: 'cr', align: 'left', sortable: false },
    {
      text: 'Actions',
      value: 'action',
      sortable: false,
      align: 'right',
      width: 100,
    },
  ],
  voucherTypeHeads: [
    { value: 'all', text: 'All' },
    { value: 'journal', text: 'Journal' },
    { value: 'payment', text: 'Payment' },
    { value: 'receipt', text: 'Receipt' },
    { value: 'invoice', text: 'Invoice' },
    { value: 'dr_cr', text: 'Dr/Cr Note' },
  ],
  ledgerHeads: [] /** Ledger Heads **/,
  voucher_type_head: 'all',

  journalTypes: [] /** Journal Types **/,
  journal_type_id: 'all',
  voucher_no: '',

  accountHeads: [] /** Account Heads **/,

  drcrList: [
    { value: 'dr', text: 'DR' },
    { value: 'cr', text: 'CR' },
  ],
  // footerProps: {
  //   'items-per-page-options': [10, 25, 50, 100, -1],
  // },
  // footerProps: { "items-per-page-options": [5, 10, 25, 50, 75] },
  // pagination: {
  //   rowsPerPage: 25,
  //   sortBy: 'is_final',
  // },
  search: null,
  items: [
    {
      journal_type: 'Payment Voucher',
      voucher_no: 12,
      napali_date: '2075-12-30',
      lf_id: 20,
      naration: 'Furniture bought company',
      particular: [
        {
          ledger_head: 'Furniture a/c',
          dr: 2000,
          cr: 0,
        },
        {
          ledger_head: 'Furniture a/c',
          dr: 3000,
          cr: 0,
        },
        {
          ledger_head: 'Cash a/c',
          dr: 0,
          cr: 2500,
        },
        {
          ledger_head: 'Bank a/c',
          dr: 0,
          cr: 2500,
        },
      ],
    },
  ],
  deleteDialog: false,
  deleteDataId: '',
  reason: '',
  /**/
};
